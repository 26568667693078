.card {
    background-color: white;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  .item {
    margin: 1rem 0;
  }
  
  .image {
    width: 100%;
    height: 20rem;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  
  .image img {
    width: 100%;
    object-fit: cover;
  }
  
  .content {
    text-align: center;
    padding: 1rem;
  }
  
  .content h3 {
    font-size: 1.25rem;
    color: #2c292b;
  }
  
  .actions {
    padding: 0rem;
    text-align: left;
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    color: #77002e;
    border: 1px solid #77002e;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #ffe2ed;
  }

  .addworkload {
    font: inherit;
    cursor: pointer;
    background-color: #FBE6D7;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-align: center;
    width: 200px;
    height: 180px;
    display: inline-block;
    margin: 0px 10px;
    
   

  }

  .droparea {
    font: inherit;
    cursor: pointer;
    background-color: #FBE6D7;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 180px;
    margin-top: 60px;
    
    
  }

  .wokloadcontainer {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    height: 200px;
    text-align: center;
  
  }

  .wokloadcontainer1 {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    height: 200px;
    text-align: center;
    background-color: #FDFCFB;
    padding: 5px;
  
  }

  .fullwidth {
    width: 100%;
    margin: 0px;
  }

  .leftmenu {
    float: left;
    width: 200px;
    padding: 20px;
    border-right: 1px solid #E2E1E0;
    margin-top:  30px;
  }

  .leftmenu a {
    text-decoration: none;
    display: block;
    padding: 0px 10px;
  
  }

  .activelink {
    text-decoration: none;
    display: block;
    padding: 0px 10px;
    color:#FC2406;
  }

  .leftmenu1 {
    float: left;
    width: 230px;
    padding: 20px 20px 20px 40px;
    margin-top:  0px;
  }

  /* .leftmenu1 a {
    text-decoration: none;
    display: block;
    padding: 0px 10px;
    color: #000;
  
  } */

  .passivelink1 {
    text-decoration: none;
    display: block;
    padding: 0px 10px;
    color: #000;
    font-size: 0.9rem;

    
    

  }

  .activelink1 {
    text-decoration: none;
    display: block;
    padding: 0px 10px;
    color:#FC2406;
    font-size: 0.9rem;
    
  }

  .tdcell {
    background-color: #F3F1F1;
  }

  .heading1 {
    font-size: 1.5rem;
  }

  .normal1 {
    font-size: 0.9rem;
  }
