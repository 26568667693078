.header {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0px 20px 0px 20px;
    

    border-bottom: 1px solid #E2E1E0;
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .logo {
    font-size: 1rem;
    color: #77002e;
    font-weight: bold;
    
  }
  
  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .header li {
    margin-left: 3rem;
  }
  
  .header a {
    text-decoration: none;
    font-size: 1rem;
    color: #494748;
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: #E7D1D1;
  }
  
  .badge {
    background-color: #FA8A46;
    color: rgb(71, 11, 11);
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
  }

  .body {
    background-color: #fff;
  }

